const rifa = [
  {
    number: 1,
    name: "Heloisa Branco Duarte",
    image: "./photos/helo.jpg"
  },
  {
    number: 2,
    name: "Mateus Campos",
    image: "./photos/mateuscampos.jpg"
  },
  {
    number: 3,
    name: "Fernanda Guedes",
    image: "./photos/fernandaguedes.jpg"
  },
  {
    number: 4,
    name: "Laura Rostelato",
    image: "./photos/laurarostelato.jpg"
  },
  {
    number: 5,
    name: "Rafael Pereira",
    image: "./photos/rafael-pereira.jpg"
  },
  {
    number: 6,
    name: "Lairton",
    image: "./photos/lairton.jpg"
  },
  {
    number: 7,
    name: "Letícia Rios",
    image: "./photos/leticiarios.jpg"
  },
  {
    number: 8,
    name: "Aloisio",
    image: "./photos/aloisio.jpg"
  },
  {
    number: 9,
    name: "Néia",
    image: "./photos/neia.jpg"
  },
  {
    number: 10,
    name: "Thiago Martins",
    image: "./photos/thiago.jpg"
  },
  {
    number: 11,
    name: "Dona Irma",
    image: "./photos/irma.jpg"
  },
  {
    number: 12,
    name: "Cristina Rennó`",
    image: "./photos/cristinarenno.jpg"
  },
  {
    number: 13,
    name: "Liv",
    image: "./photos/liv.jpg"
  },
  {
    number: 14,
    name: "Aloisio",
    image: "./photos/aloisio.jpg"
  },
  {
    number: 15,
    name: "Fernanda Guedes",
    image: "./photos/fernandaguedes.jpg"
  },
  {
    number: 16,
    name: "Laura Rennó",
    image: "./photos/laurarenno.jpg"
  },
  {
    number: 17,
    name: "Aloisio",
    image: "./photos/aloisio.jpg"
  },
  {
    number: 18,
    name: "Elisa Rennó",
    image: "./photos/elisarenno.jpg"
  },
  {
    number: 19,
    name: "Aline Rennó",
    image: "./photos/alinerenno.jpg"
  },
  {
    number: 20,
    name: "Néia",
    image: "./photos/neia.jpg"
  },
  {
    number: 21,
    name: "Marcos Duarte",
    image: "./photos/marcosdduarte.jpg"
  },
  {
    number: 22,
    name: "Sandra Rennó",
    image: "./photos/sandrarenno.jpg"
  },
  {
    number: 23,
    name: "Mateus Campos",
    image: "./photos/mateuscampos.jpg"
  },
  {
    number: 24,
    name: "Mateus Campos",
    image: "./photos/mateuscampos.jpg"
  },
  {
    number: 25,
    name: "Fábio Rios",
    image: "./photos/fabiomilaurinha.jpg"
  },
  {
    number: 26,
    name: "Michelle Luz",
    image: "./photos/fabiomilaurinha.jpg"
  },
  {
    number: 27,
    name: "Laurinha Luz Rios",
    image: "./photos/fabiomilaurinha.jpg"
  },
  {
    number: 28,
    name: "Erika Okyama",
    image: "./photos/erikaokyama.jpg"
  },
  {
    number: 29,
    name: "Lucas Rennó",
    image: "./photos/lucasrenno.jpg"
  },
  {
    number: 30,
    name: "Davi Moraes",
    image: "./photos/davimoraes.jpg"
  },
  {
    number: 31,
    name: "Marcos Duarte",
    image: "./photos/marcosdduarte.jpg"
  },
  {
    number: 32,
    name: "Vó Benedita",
    image: "./photos/lucasrenno.jpg"
  },
  {
    number: 33,
    name: "Júnior",
    image: "./photos/junior.jpg"
  },
  {
    number: 34,
    name: "Mateus Campos",
    image: "./photos/mateuscampos.jpg"
  },
  {
    number: 35,
    name: "Aloisio",
    image: "./photos/aloisio.jpg"
  },
  {
    number: 36,
    name: "Tia Laisinha",
    image: "./photos/laisinha.jpg"
  },
  {
    number: 37,
    name: "Rodrigo Sussumu",
    image: "./photos/sussumu.jpg"
  },
  {
    number: 38,
    name: "Júlia Souza",
    image: "./photos/julia.jpg"
  },
  {
    number: 39,
    name: "Rodrigo Sussumu",
    image: "./photos/sussumu.jpg"
  },
  {
    number: 40,
    name: "Hebe Rios",
    image: "./photos/hebe.jpg"
  },
  {
    number: 41,
    name: "Tia Laisinha",
    image: "./photos/laisinha.jpg"
  },
  {
    number: 42,
    name: "Lais Rios",
    image: "./photos/laisribeirorios.jpg"
  },
  {
    number: 43,
    name: "",
    image: ""
  },
  {
    number: 44,
    name: "Filipe Rios",
    image: "./photos/filipe.jpg"
  },
  {
    number: 45,
    name: "",
    image: ""
  },
  {
    number: 46,
    name: "",
    image: ""
  },
  {
    number: 47,
    name: "",
    image: ""
  },
  {
    number: 48,
    name: "",
    image: ""
  },
  {
    number: 49,
    name: "Lucas Consoli",
    image: "./photos/luscas.jpg"
  },
  {
    number: 50,
    name: "Rosângela",
    image: "./photos/rosangela.jpg"
  },
  {
    number: 51,
    name: "Marcela Rocha",
    image: "./photos/marcelarocha.jpg"
  },
  {
    number: 52,
    name: "Rosângela",
    image: "./photos/rosangela.jpg"
  },
  {
    number: 53,
    name: "Mateus Campos",
    image: "./photos/mateuscampos.jpg"
  },
  {
    number: 54,
    name: "Rafael Pereira",
    image: "./photos/rafael-pereira.jpg"
  },
  {
    number: 55,
    name: "Viviane",
    image: "./photos/vivi.jpg"
  },
  {
    number: 56,
    name: "",
    image: ""
  },
  {
    number: 57,
    name: "",
    image: ""
  },
  {
    number: 58,
    name: "",
    image: ""
  },
  {
    number: 59,
    name: "Néia",
    image: "./photos/neia.jpg"
  },
  {
    number: 60,
    name: "Victo Rennó",
    image: "./photos/victo.jpg"
  },
  {
    number: 61,
    name: "Lais Rios",
    image: "./photos/laisribeirorios.jpg"
  },
  {
    number: 62,
    name: "",
    image: ""
  },
  {
    number: 63,
    name: "Mariana Lima",
    image: "./photos/marianalima.jpg"
  },
  {
    number: 64,
    name: "Camila Guedes",
    image: "./photos/camilaguimaraes.jpg"
  },
  {
    number: 65,
    name: "",
    image: ""
  },
  {
    number: 66,
    name: "Vera Lúcia Rennó",
    image: "./photos/vera.jpg"
  },
  {
    number: 67,
    name: "Lucas Consoli",
    image: "./photos/luscas.jpg"
  },
  {
    number: 68,
    name: "Raimundo",
    image: "./photos/raimundo.jpg"
  },
  {
    number: 69,
    name: "Rafael Pereira",
    image: "./photos/rafael-pereira.jpg"
  },
  {
    number: 70,
    name: "Dimas Rennó",
    image: "./photos/dimas.jpg"
  },
  {
    number: 71,
    name: "",
    image: ""
  },
  {
    number: 72,
    name: "Mateus Campos",
    image: "./photos/mateuscampos.jpg"
  },
  {
    number: 73,
    name: "",
    image: ""
  },
  {
    number: 74,
    name: "Daniel Roberti",
    image: "./photos/danielroberti.jpg"
  },
  {
    number: 75,
    name: "",
    image: ""
  },
  {
    number: 76,
    name: "",
    image: ""
  },
  {
    number: 77,
    name: "Juliane",
    image: "./photos/juliane.jpg"
  },
  {
    number: 78,
    name: "",
    image: ""
  },
  {
    number: 79,
    name: "Hebe Rios",
    image: "./photos/hebe.jpg"
  },
  {
    number: 80,
    name: "João Gabriel",
    image: "./photos/joaogabriel.jpg"
  },
  {
    number: 81,
    name: "Tio Branco",
    image: "./photos/branco.jpg"
  },
  {
    number: 82,
    name: "Laura",
    image: "./photos/laurarenno.jpg"
  },
  {
    number: 83,
    name: "",
    image: ""
  },
  {
    number: 84,
    name: "",
    image: ""
  },
  {
    number: 85,
    name: "Camila Pierino",
    image: "./photos/camilapierino.jpg"
  },
  {
    number: 86,
    name: "Telmo Campos",
    image: "./photos/telmo.jpg"
  },
  {
    number: 87,
    name: "",
    image: ""
  },
  {
    number: 88,
    name: "Néia",
    image: "./photos/neia.jpg"
  },
  {
    number: 89,
    name: "Mateus Campos",
    image: "./photos/mateuscampos.jpg"
  },
  {
    number: 90,
    name: "Mariana Iannaconi",
    image: "./photos/marianaiannaconi.jpg"
  },
  {
    number: 91,
    name: "Mateus Campos",
    image: "./photos/mateuscampos.jpg"
  },
  {
    number: 92,
    name: "",
    image: ""
  },
  {
    number: 93,
    name: "Pedro Moraes",
    image: "./photos/pedro.jpg"
  },
  {
    number: 94,
    name: "Erika Okyama",
    image: "./photos/erikaokyama.jpg"
  },
  {
    number: 95,
    name: "Daniel Roberti",
    image: "./photos/danielroberti.jpg"
  },
  {
    number: 96,
    name: "Samuel",
    image: "./photos/samuelzinho.jpg"
  },
  {
    number: 97,
    name: "Desirrêe Dias",
    image: "./photos/desi.jpg"
  },
  {
    number: 98,
    name: "",
    image: ""
  },
  {
    number: 99,
    name: "Lais Rios",
    image: "./photos/laisribeirorios.jpg"
  },
  {
    number: 100,
    name: "Caio Branco",
    image: "./photos/caio.jpg"
  },
  {
    number: 101,
    name: "Rafael Pereira",
    image: "./photos/rafael-pereira.jpg"
  },
  {
    number: 102,
    name: "",
    image: ""
  },
  {
    number: 103,
    name: "",
    image: ""
  },
  {
    number: 104,
    name: "Pâm",
    image: "./photos/pam.jpg"
  },
  {
    number: 105,
    name: "",
    image: ""
  },
  {
    number: 106,
    name: "",
    image: ""
  },
  {
    number: 107,
    name: "",
    image: ""
  },
  {
    number: 108,
    name: "",
    image: ""
  },
  {
    number: 109,
    name: "",
    image: ""
  },
  {
    number: 110,
    name: "",
    image: ""
  },
  {
    number: 111,
    name: "Júnior",
    image: "./photos/junior.jpg"
  },
  {
    number: 112,
    name: "Hebe Rios",
    image: "./photos/hebe.jpg"
  },
  {
    number: 113,
    name: "Mariana Iannaconi",
    image: "./photos/marianaiannaconi.jpg"
  },
  {
    number: 114,
    name: "Thiago Martins",
    image: "./photos/thiago.jpg"
  },
  {
    number: 115,
    name: "",
    image: ""
  },
  {
    number: 116,
    name: "",
    image: ""
  },
  {
    number: 117,
    name: "Lais Rios",
    image: "./photos/laisribeirorios.jpg"
  },
  {
    number: 118,
    name: "Aline Martins",
    image: "./photos/alinemartins.jpg"
  },
  {
    number: 119,
    name: "Laura Rostelato",
    image: "./photos/laurarostelato.jpg"
  },
  {
    number: 120,
    name: "Lavínia Rennó",
    image: "./photos/laviniarenno.jpg"
  },
]

export default rifa;